import { parseConfigItems } from './config-items-parser';
import { init as createUserService, UserService } from './services/user';
import { buildCurrentPath } from './services/location';
import { renderLoginMenus, renderMembersMenus } from './services/menu-renderer';
import { initializeMonitoring, toMonitored, log } from './utils/monitoring';
import { GroupsService, initGroupsService } from './services/groups';
import { getPagesFromRouters } from './services/page-mapper';
import { setMemoryStorage, getMemoryStorage } from './services/memory-storage';
import { initExperiments, WixExperiments } from './services/experiments';
import { initHttpClient } from './services/http-client';
import { PublicAPI } from './public-api';
import {
  Callback,
  Config,
  Member,
  MemberInfo,
  RawRouter,
  ReturnedRouterData,
  RouterConfig,
  SectionData,
  WixCodeApi,
} from './common/types';

let _config: Config;
let experiments: WixExperiments | null;
let publicApi: PublicAPI;
let userService: UserService;
let groupsService: GroupsService;

const _initAppForPage = (
  { routerReturnedData, appRouters = [] }: { routerReturnedData: ReturnedRouterData; appRouters?: RawRouter[] },
  { storage }: { storage: Storage },
  wixCodeApi: WixCodeApi,
) => {
  userService = createUserService(wixCodeApi);
  publicApi = new PublicAPI({ appRouters, routerReturnedData, wixCodeApi });
  setMemoryStorage(storage.memory);

  if (!routerReturnedData) {
    return Promise.resolve();
  }

  const slugs = (routerReturnedData.memberData && routerReturnedData.memberData.slugs) || [];
  const viewedUserId =
    (routerReturnedData.memberData && routerReturnedData.memberData.memberContactId) || routerReturnedData.userId;
  const primarySlug = slugs.find((slug) => slug.primary === true);
  const viewedUserSlug = (primarySlug && primarySlug.name) || viewedUserId;
  const viewedUserData: Member = { id: viewedUserId!, slug: viewedUserSlug! };

  userService.setViewedUser(viewedUserData);
  userService.setRoles(routerReturnedData.roles || {});

  return Promise.resolve();
};

function fetchAndRenderMenus({ wixCodeApi, appParams, config, $w }: Config) {
  const viewedUser = userService.getViewedUser();
  const currentUser = userService.getCurrentUser();
  const userRoles = userService.getRoles();
  const needToFetchRoles = Object.keys(userRoles).length === 0 && currentUser.loggedIn;
  const santaMembersToken = wixCodeApi.site.getAppToken(appParams.appDefinitionId);
  const isSSR = wixCodeApi.window.rendering.env === 'backend';
  const parsedRouters = (appParams.appRouters || []).map((router) => ({
    ...router,
    config: JSON.parse(router.config),
  }));
  const parsedRoutersConfigs = parsedRouters.map((router) => router.config);

  return Promise.all([
    needToFetchRoles && userService.fetchRoles(viewedUser.id, currentUser.id, santaMembersToken),
    !isSSR ? userService.getViewedUserMenuCountersWithCaching(santaMembersToken) : undefined,
    !isSSR ? userService.getCurrentUserMenuCountersWithCaching(santaMembersToken) : undefined,
    groupsService.getPermittedPagesMap(getPagesFromRouters(parsedRouters), wixCodeApi.window.viewMode),
  ]).then(([roles, viewedUserCounters, currentUserCounters, permittedPagesMap]) => {
    if (needToFetchRoles) {
      userService.setRoles(roles);
    }

    const parsedConfigItems = parseConfigItems(config);
    const memoryStorage = getMemoryStorage();
    const isMobile = wixCodeApi.window.formFactor === 'Mobile';
    const currentUserRoles = userService.getRoles()[currentUser.id] || [];
    const viewedUserRoles = userService.getRoles()[viewedUser.id] || [];
    const publicRouter = parsedRouters.find((router) => router.config.type === 'public');
    const publicRouterPrefix = publicRouter?.prefix ?? '';

    toMonitored('renderMembersMenuItems', () =>
      renderMembersMenus({
        $w,
        wixCodeApi,
        parsedRoutersConfigs,
        viewedUserRoles,
        viewedUser,
        currentUser,
        appsCounters: viewedUserCounters,
        parsedConfigItems,
        memoryStorage,
        publicRouterPrefix,
        permittedPagesMap,
        experiments,
        isMobile,
      }),
    )();
    toMonitored('renderLoginMenuItems', () =>
      renderLoginMenus({
        $w,
        parsedRoutersConfigs,
        currentUserRoles,
        currentUser,
        appsCounters: currentUserCounters,
        memoryStorage,
        publicRouterPrefix,
        permittedPagesMap,
        experiments,
        isMobile,
      }),
    )();
  });
}

// Old method, making sure whether it is still needed with logs
const redirectIfURLIsInvalid = (config: Config) => {
  const viewedUser = userService.getViewedUser();
  if (config.wixCodeApi.window.viewMode === 'Site') {
    const url = buildCurrentPath(config.wixCodeApi);
    const urlWithSlug = userService.replaceUserPatternWithSlug(url, viewedUser);
    if (url !== urlWithSlug) {
      const tags = { viewerName: config.platformAPIs.bi.viewerName };
      log('Deprecation check: redirect', {
        tags,
        extra: { from: url, to: urlWithSlug },
      });
      config.wixCodeApi.location.to(urlWithSlug);
    }
  }
};

const createPageReady = (config: Config) => () => {
  const { wixCodeApi, appParams } = config;
  _config = config;

  const setUserAndRenderMenus = (user = wixCodeApi.user.currentUser) => {
    const instance = wixCodeApi.site.getAppToken(appParams.appDefinitionId);
    return userService.setCurrentUser(user, instance).then(() => fetchAndRenderMenus(config));
  };

  wixCodeApi.user.onLogin((loggedInUser: Member) =>
    toMonitored('onLogin', () => setUserAndRenderMenus(loggedInUser))(),
  );

  return initExperiments(config)
    .then((_experiments) => {
      experiments = _experiments;
      groupsService = initGroupsService(initHttpClient(config), experiments);
    })
    .then(() => setUserAndRenderMenus())
    .then(() => redirectIfURLIsInvalid(config));
};

const _createControllers = (controllerConfigs: Config[]) =>
  controllerConfigs.map((config) => ({
    pageReady: createPageReady(config),
    exports: {},
  }));

const initAppForPage = (initParams: any, platformApis: any, wixCodeApi: WixCodeApi, platformServices: any) => {
  initializeMonitoring(initParams, platformServices);
  return toMonitored('initAppForPage', () => _initAppForPage(initParams, platformApis, wixCodeApi))();
};

const createControllers = (controllerConfigs: Config[]) =>
  toMonitored('createControllers', () => _createControllers(controllerConfigs))();

const platformExports = {
  hasSocialPages: (onSuccess: Callback, onError: Callback) =>
    toMonitored('publicApi.hasSocialPages', () => publicApi.hasSocialPages(onSuccess, onError))(),
  getViewedUser: (onSuccess: Callback, onError: Callback) =>
    toMonitored('publicApi.getViewedUser', () => publicApi.getViewedUser(onSuccess, onError))(),
  navigateToSection: (sectionData: SectionData, onError: Callback) =>
    toMonitored('publicApi.navigateToSection', () => publicApi.navigateToSection(sectionData, onError))(),
  navigateToMember: (memberInfo: MemberInfo, onError: Callback) =>
    toMonitored('publicApi.navigateToMember', () => publicApi.navigateToMember(memberInfo, onError))(),
  getNavigatableRoles: (onError: Callback) =>
    toMonitored('publicApi.getNavigatableRoles', () => publicApi.getNavigatableRoles())(),
  getSectionUrl: (sectionData: SectionData, onError: Callback) =>
    toMonitored('publicApi.getSectionUrl', () => publicApi.getSectionUrl(sectionData, onError))(),
  getMemberPagePrefix: (data: RouterConfig, onSuccess: Callback, onError: Callback) =>
    toMonitored('publicApi.getMemberPagePrefix ', () => publicApi.getMemberPagePrefix(data, onSuccess, onError))(),
  setNotificationCount: (displayCount: number) =>
    toMonitored('publicApi.setNotificationCount', () => publicApi.setNotificationCount(displayCount))(),
  enterPublicProfilePreviewMode: () =>
    toMonitored('publicApi.enterPublicProfilePreviewMode', () =>
      publicApi.enterPublicProfilePreviewMode({
        userService,
        config: _config,
      }),
    )(),
  leavePublicProfilePreviewMode: () =>
    toMonitored('publicApi.leavePublicProfilePreviewMode', () =>
      publicApi.leavePublicProfilePreviewMode({
        userService,
        config: _config,
      }),
    )(),
  clearMenus: () => toMonitored('publicApi.clearMenus', () => publicApi.clearMenus({ config: _config }))(),
};

export { platformExports as exports, initAppForPage, createControllers };
