import { WIX_BLOG, WIX_FORUM, WIX_EVENTS, WIX_GROUPS } from '@wix/app-definition-ids';

export const USER_NAME_PATTERN = '{userName}';

export const UUID_PATTERN = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const SOCIAL_APPS = [
  WIX_BLOG,
  WIX_FORUM,
  WIX_GROUPS,
  WIX_EVENTS,
  '14ebe801-d78a-daa9-c9e5-0286a891e46f', // FOLLOWERS
  '14dbef06-cc42-5583-32a7-3abd44da4908', // ABOUT
];

export const RESTRICTED_MA_PAGES_EXPERIMENT = 'specs.membersArea.EnableMemberPagePermissions';
